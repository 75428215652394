import React from 'react';
import ReactPlayer from 'react-player';
import Const from '../components/Const';
import { setUnsupportedVideoCodecMessage, setUnsupportedVideoAudioMessage } from '../components/Utils';
import { isTabletLandscapeLayout, isiPhone } from './AppUtils';
import Envir from '../components/Envir';

let videoUploadDimensions = null;

export function renderVideoPlayer(url, styleObj, videoClass, muted, that, instance, props = {}) {
  const onErrorHandle = (event) => {
    if (that && that.parent) {
      // for video preview only
      if (event.srcElement && event.srcElement.error && event.srcElement.error.message) {
        if (event.srcElement.error.message.toLowerCase().includes('audio')) {
          setUnsupportedVideoAudioMessage(that.parent);
        }
      }
      else {
        setUnsupportedVideoCodecMessage(that.parent);
      }
      that.parent.onErrorPopUpClose = onErrorPopUpClose; // to remove videpo preview on error pop-up close
    }
  };

  const onReadyHandle = () => {
    if (instance.player.current && !instance.onReadyDone) {
      instance.player.current.seekTo(0.005, 'fraction'); // to avoid a black screen
      instance.onReadyDone = true;
    }
  };

  const onReadyHandleDummy = () => {
  };

  return (
    <div style = { styleObj } >
      <ReactPlayer className={`${videoClass}`} 
        playsinline
        url={url}
        controls
        muted={ muted }
        onError = { onErrorHandle }
        onReady = { (instance && !props.playing) ? onReadyHandle : onReadyHandleDummy }
        ref={ instance ? instance.player : null}
        {...props}
      />
    </div>
  );
}

export function onErrorPopUpClose(that) {
  if (that && that.ImageUploadder.current) {
    that.ImageUploadder.current.setState({ imageIsNotUploaded : true, videoPreviewUrl : '', file : '' });
    delete that.onErrorPopUpClose;
  }
};

export function renderVideoPlayerWrapperM(post, playing, loop, muted = true) {
  let videoUrl = post.postVideo;
  let width;
  let height;
  let adjustment = 1;// making sure border is correctly displayed around video
  let videoPortretHeight = Const.POST_IMG_MAX_HEIGHT_PORTRAIT;
  let koeff;
  let myPlaying = playing;
  let myMuted = muted;
  let widthString;
  let heightString;
  let videoClass = 'post-video';
  let styleObj;
  let playerRef = React.createRef(); 
  let instance = { player : playerRef, onReadyDone: false};

  if (post.imgHeight > post.imgWidth) {
    koeff = post.imgWidth / post.imgHeight;
    height = videoPortretHeight;
    width = koeff * height;
    if ((window.innerWidth - adjustment) <=  width) {
      width = window.innerWidth - adjustment;
      height = koeff * width;
    }
    widthString = width + 'px';
    heightString = height + 'px';
  }
  else {
    koeff = post.imgHeight / post.imgWidth;
    if (isTabletLandscapeLayout()) {
      width = Const.POST_IMG_MAX_WIDTH_LENDSCAPE;
      height = width * koeff;
      widthString = width + 'px';
      heightString = height + 'px';
    }
    else {
      width = 100;
      height = width * koeff;
      widthString = width + '%';
      heightString = height + '%';
    }
  }
  styleObj = { width:  widthString, height: heightString };
  if (playing && muted) {
    myPlaying = false;
    myMuted = false;
  }

  return renderVideoPlayer(videoUrl, styleObj, videoClass, myMuted, null, instance, { 
                            playing: myPlaying, 
                            loop: loop,
                            width: widthString,
                            height: heightString
                          });
}

export function renderVideoPlayerWrapper(videoUrl, playing, loop, that, muted = true) {
  let { file } = that.state;
  let koeff;
  let height;
  let width;
  let widthString;
  let heightString;
  let styleObj = {};
  let videoClass = 'upload-video';
  let instance = null;
  
  if (!videoUploadDimensions) {
    videoUploadDimensions = getAvailableDimensionsForVideoUpLoad();
  }
  height = videoUploadDimensions.height;
  width = file.width * (height / file.height); // proportionaly increased or decreased width
  if (width > videoUploadDimensions.width) {
    koeff = file.height / file.width;
    width = 100; // % we made width smaller
    height = width * koeff;
    widthString = width + '%';
    heightString = height + '%';
  }
  else {
    widthString = width + 'px';
    heightString = height + 'px';
  }
  return renderVideoPlayer(videoUrl, styleObj, videoClass, muted, that, instance, { 
                            playing: playing, 
                            loop: loop,
                            width: widthString,
                            height: heightString
                          });
}

export function getAvailableDimensionsForVideoUpLoad() {
  let topPadding = 16 // because of margin-top: 1rem ;
  let bottomPadding = 10; // to make it safe
  let uploadImgOrVideo = document.getElementById('uploadImgOrVideoId');
  let textArea = document.getElementById('textArea');
  let main = document.getElementById('main');
  let rect = uploadImgOrVideo.getBoundingClientRect();
  let mainHeight = (Envir.iOS_APP && isiPhone()) ? window.innerHeight : main.offsetHeight;
  let availableHeight = mainHeight - (rect.top + uploadImgOrVideo.clientHeight + topPadding + bottomPadding);
                        // the available height of the area below text area icons
  let availableWidth = textArea.offsetWidth;
  
  let videoUploadDimensions = {
                                width : availableWidth,
                                height : availableHeight
                              }
  return videoUploadDimensions;
}