import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { MDBModal, MDBModalBody } from 'mdbreact';
import * as MdIcons from 'react-icons/md';
import Const from '../Const';
import ImageUpload from '../ImageUpload';
import { invokeService, messageModalWindow, dateToNumberDate, changeHandler, changeHandlerNoEvent,
         setPostTextOverflow, readLocalImageFile, getImageParams, getUserPictureForAIClassName, processVideo } from '../Utils.js';
import { LOGIN_USER, SEARCH, underlyingComponentPositionProtector, getSpinner } from '../ScrollUtils.js';
import { invokeServer } from '../../utils/SharePostUtils';
import Consierge from '../../images/consierge-greet-avatar.png';
import Edit from '../../images/edit.jpeg';
import Morning from '../../images/morning.jpeg';
import Kotik from '../../images/kotik.jpeg';
import Video from '../../images/video.jpeg';
import { storeState, getState } from '../../utils/StateStoreUtils.js';
import { getBottomBar, getEmojiPicker, postsAndFollowersClassNames, storeUserPostInDB } from '../../utils/PostUtils.js';
import { iOSstatusBarPadding } from '../../utils/CapacitorUtils';

export default class GetYourPostBase extends Component {
	constructor(props) {
		super(props);
		const { getYourPostParams } = props;
		const { loginUser, that, parent, post } = getYourPostParams;
    this.className = 'GetYourPostBase';
    this.TIMEOUT = 700;
		this.allowToggle = true;
		this.MAX_LENGTH = 280 * 2;
		this.DELTA = 99;
		this.MAX_LENGTH_STRING = (this.MAX_LENGTH + this.DELTA).toString();
    this.AI_POST_EDIT = 1;
    this.AI_POST_CREATE = 2;
    this.AI_POST_ILLUSTRATE = 3;
    this.AI_IMAGE_CREATE = 4;
    this.AI_POST_VIDEO_ILLUSTRATE = 5;
    this.AI_IMAGE_CREATE_PREFIX = '';
    this.AI_POST_EDIT_TYPE = 1;
    this.AI_POST_CREATE_TYPE = 2;
    this.AI_POST_ILLUSTRATE_TYPE = 3;
    this.AI_POST__VIDEO_ILLUSTRATE_TYPE = 4;
    this.AI_POST_EDIT_PLACE_HOLDER = 'Post content for editing';
    this.AI_POST_CREATE_PLACE_HOLDER = 'Post description';
    this.AI_POST_ILLUSTRATE_PLACE_HOLDER = 'Post content for image illustration';
    this.AI_POST_VIDEO_ILLUSTRATE_PLACE_HOLDER = 'Post content for video illustration';
    this.AI_IMAGE_PLACE_HOLDER = 'Image description';
    this.BOLD_FONT = { opacity : Const.FULL_OPACITY };
    this.LIGHT_FONT = { opacity : Const.REDUCED_OPACITY };
    this.REMAINING_AI_POSTS = " AI edits left for this month";
    this.REMAINING_AI_IMAGES = " AI images/videos left for this month";
    this.BLUE_COLOR = '#30d2fa';
    this.BOLD_COLORED_BORDER = { borderWidth : '6px', borderStyle : 'solid', borderColor : this.BLUE_COLOR };
    this.BOLD_BORDER = { borderWidth : '1px', borderStyle : 'solid' };
    this.pfClassNames = postsAndFollowersClassNames('');
    this.BACKGROUND_IMAGE_CLASS = this.pfClassNames.bodyFullscreen3; //"body-fullscreen-3";
    this.BACKGROUND_IMAGE_CLASS_NO_IMAGE = "body-fullscreen-3-noimage";
    this.AI_POST_USER_NAME_HEADER = 'AI Concierge will';
    this.POST_USER_NAME_HEADER = 'Create My Post';
    this.AI_INPUT_MAX_LEN = 500;
		this.state = {
        post : post,
        value: post? post.post  : '',
        messageModal: false,
        loginUser : loginUser,
        parent : that,
        grandParent : parent,
        header : '',
        text : '',
        disabled : true,
        disabledAI : false,
        imgIckonDisabled : false,
        noPostText : true,
        imageIsNotUploaded : true,
        charCounter : this.MAX_LENGTH,
        autoFocus : true,
        postAdded : false,
        init : false,
        AIrequestOption : this.AI_POST_EDIT,
        AIrequestPrefix : this.AI_POST_EDIT,
        AIrequestType : this.AI_POST_EDIT_TYPE,
        AIrequestPlaceHolder : this.AI_POST_EDIT_PLACE_HOLDER,
        borderAI : this.BOLD_COLORED_BORDER,
        borderUser : this.BOLD_BORDER,
        fontWeightAI : this.BOLD_FONT,
        fontWeightUser : this.LIGHT_FONT,
        backgroundImageClass : this.BACKGROUND_IMAGE_CLASS,
        messageForAI : '',
        editPostMessageForAI : '',
        createPostMessageForAI : '',
        createImageMessageForAI : '',
        submitToAINotSentYet: true,
        submitUserPostNotSentYet : true,
        visibilityHidden : { visibility : 'hidden' },
        numberOfAIRequestsLeft : loginUser.numberOfAIpostRequests,
        remainingRequestText : this.REMAINING_AI_POSTS,
        aiInputClassName : "",
        AIpostUserNameHeader : this.AI_POST_USER_NAME_HEADER,
        postUserNameHeader : this.POST_USER_NAME_HEADER,
        showEmojiEmojiPicker : false,
        serverUrl : '',
        showFaces : true,
        showHeaderArea : true,
			  //
        serviceInvocationStatus : Const.SERVICE_IS_NOT_INVOKED_YET,
        serviceStatusCode : Const.INIT_VALUE,
        serviceErrorLevel : Const.NO_ERROR_LEVEL,
        serviceInvocationError : false,
        flag : Const.INIT_VALUE,
        activeButton: 'button1',
        message: '',
        innerWidth : 0
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmitToAI = this.handleSubmitToAI.bind(this);
    this.setAIrequestOptionOnClick = this.setAIrequestOptionOnClick.bind(this);
    this.handleAIinputChange = this.handleAIinputChange.bind(this);
    this.handleEmojiCloseClick = this.handleEmojiCloseClick.bind(this);
		this.ImageUploadder = React.createRef();
		this.radioButtons = React.createRef();
		this.threeDots = React.createRef();
    this.buttonOffsetTop = null;
		this.textOverflow = false;
		this.timeoutID = -1;
		this.imagePreviewUrl = null;
    this.videoPreviewUrl = null;
		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.localServeUrlAI = Const.VPS_SERVER_URL_AI;
    this.localServeUrlDalee = Const.VPS_SERVER_URL_DALEE;
     this.localServerUrlIllustrate = Const.VPS_SERVER_URL_ILLUSTRATE;
    this.localServerVideoUrlIllustrate = Const.VPS_SERVER_URL_VIDEO_ILLUSTRATE;
    this.currentVideoFile = null;
	}

	handleButtonClick(buttonName) {
    // Disabled for AI concierge button
    let numberOfAIRequestsLeft;

    this.higlightSelectedElement(buttonName);
    numberOfAIRequestsLeft = this.getNumberAIRequestsLeft(this.state.AIrequestOption);
    this.setState({
      numberOfAIRequestsLeft : numberOfAIRequestsLeft
    });
	}

  higlightSelectedElement(buttonName) {
    let button1StateObj;

    if (buttonName === 'button1') {
      button1StateObj = { 
          activeButton : 'button1',
          borderAI : this.BOLD_COLORED_BORDER, 
          fontWeightAI : this.BOLD_FONT, 
          borderUser : this.BOLD_BORDER,
          fontWeightUser : this.LIGHT_FONT,
          backgroundImageClass : this.BACKGROUND_IMAGE_CLASS
      };
      this.setState( button1StateObj );
    }
    else {
      this.setState({ 
                      activeButton : 'button2',
                      borderAI : this.BOLD_BORDER, 
                      fontWeightAI : this.LIGHT_FONT, 
                      borderUser : this.BOLD_COLORED_BORDER,
                      fontWeightUser : this.BOLD_FONT,
                      backgroundImageClass : this.BACKGROUND_IMAGE_CLASS_NO_IMAGE
                    });
    }
  }

 	//message

	handleInputChange(event) {
		this.setState({
		  message: event.target.value,
		});
  }

  init() {
    let loginUser = this.state.grandParent.state.loginUser;
    let numberOfAIRequestsLeft = this.getNumberAIRequestsLeft(this.AI_POST_EDIT);
    let aiPostLimitCond = this.aiPostLimitCondition(loginUser);
    let aiImageLimitCond = this.aiImageLimitCondition(loginUser);
    let activeButton = (aiPostLimitCond  || aiImageLimitCond) ? 'button1' : 'button2';
    
    if (loginUser.hasOwnProperty('createPostWithAI') && !loginUser.createPostWithAI) {
      activeButton = 'button2';
    }
    this.higlightSelectedElement(activeButton);
    this.setState ({ 
                    activeButton : activeButton, 
                    numberOfAIRequestsLeft : numberOfAIRequestsLeft,
                    remainingRequestText : this.REMAINING_AI_POSTS,
                    AIrequestOption : this.AI_POST_EDIT, 
                    AIrequestType : this.AI_POST_EDIT_TYPE,
                    AIrequestPlaceHolder : this.AI_POST_EDIT_PLACE_HOLDER,
                    messageForAI : '',
                    editPostMessageForAI : '',
                    createPostMessageForAI : '',
                    createImageMessageForAI : '',
                    showEmojiEmojiPicker : false
                   });
  }

	toggle = () => {
		let value;
    let toggleModal = !this.state.modal; // true - modal window is about to pop-up; 
                                         // false - modal window is about close 
    
		if (this.allowToggle) {
			this.setStateAttributes();
      underlyingComponentPositionProtector(toggleModal, this.state.grandParent);
			if (toggleModal) {
        this.observeOrientationChange(toggleModal);
        if (this.state.grandParent) { // insure it's not update post
          this.init();
        }
			  // we will pop modal window in this toggle invocation,
		   	value = this.getPostContent();
			  this.setState({ value : value, noPostText : true,  charCounter : this.MAX_LENGTH, 
									      imageIsNotUploaded : true });
			}
			this.setState({ modal : toggleModal });
		}
	}

  async handleChange(event) {
    changeHandler(event, this);
  }

  handleImageChange(e) {
    let isPostImage;

    if (this.allowToggle) {
      e.preventDefault();
      this.ImageUploadder.current.parent = this;
      isPostImage = true;
      readLocalImageFile(e, this.ImageUploadder.current, isPostImage); // after image resiazing there is a processing in a call back function
    }
  }

  async handleSubmit(event) {
    let today;
    let todayNumber;
    let lastSubmittedPost;
    let result;
    let post = this.state.value;

    event.preventDefault();
    this.processUserPostHashTags(post);
    this.imagePreviewUrl = this.ImageUploadder.current.state.imagePreviewUrl;
    this.videoPreviewUrl = this.ImageUploadder.current.state.videoPreviewUrl;
    this.ImageUploadder.current.setState({ playing : false });
    this.disableModalWindow(true);
    today = new Date();
    todayNumber = dateToNumberDate(today);
    result = await storeUserPostInDB(todayNumber, this);
    this.disableModalWindow(false); // significant: to enable toggle
    this.toggle();
    if (this.state.serviceErrorLevel === Const.NO_ERROR_LEVEL) {
      lastSubmittedPost = this.assembleLastSubmittedPost(result);
      this.addToUserPosts(lastSubmittedPost);
    }
    else {
      this.setState({ value : '', charCounter : this.MAX_LENGTH });
    }
  }

  processUserPostHashTags(post) {
    let postHashTags = this.extractHashtagsFromUserPost(post);

    if (postHashTags.length > 0) {
      this.storeUserPostHashTagsInDB(post); 
      this.appendUniqueHashTagsToSearchDropDown(postHashTags);
    }
  }

  extractHashtagsFromUserPost(post) {
    let regex = /#([A-Za-z0-9]{2,})/g;
    let matches = post.match(regex);
  
    if (matches) {
      return matches.map((match) => match.toLowerCase());
    } else {
      return [];
    }
  }

  appendUniqueHashTagsToSearchDropDown(postHashTags) {
    let storedState = getState(SEARCH);
    let defaultHashTags = storedState ? storedState.defaultHashTags : null;
    let uniqueHashTags;
  
    if (defaultHashTags) {
      uniqueHashTags = new Set(defaultHashTags);
      postHashTags.forEach(tag => {
        if (!uniqueHashTags.has(tag)) {
          uniqueHashTags.add(tag);
        }
      });
      defaultHashTags = Array.from(uniqueHashTags);
      defaultHashTags.sort();
      storedState.defaultHashTags = defaultHashTags;
      storeState(SEARCH, storedState);
    }
  }

  storeUserPostHashTagsInDB(post) {
    let data = {
        mode : Const.STORE_HASH_TAGS,
        post : (post.length <= this.MAX_LENGTH) ? post : post.substring(0, this.MAX_LENGTH)
    };

    invokeService(Const.OCA_SERVICES_URL, data, this); // we don't await here
  }

  storeAIinfoInDB() {
    let loginUser = this.state.grandParent.state.loginUser; 
    let AIrequestOption = this.state.AIrequestOption;
    let dateObj = new Date();
    let month = dateObj.getMonth() + 1; //months from 1-12
    let year = parseInt(dateObj.getFullYear().toString().substring(3), 10);

    if ((AIrequestOption === this.AI_IMAGE_CREATE) || (AIrequestOption === this.AI_POST_ILLUSTRATE) || (AIrequestOption === this.AI_POST_VIDEO_ILLUSTRATE)) {
      if (loginUser.numberOfAIimageRequests >= 0) {
        loginUser.numberOfAIimageRequests --;
        this.setState({ numberOfAIRequestsLeft : loginUser.numberOfAIimageRequests });
      }
    }
    else {
      if (loginUser.numberOfAIpostRequests >= 0) {
        loginUser.numberOfAIpostRequests --;
        this.setState({ numberOfAIRequestsLeft : loginUser.numberOfAIpostRequests });
      }
    }
    let data = {
        mode : Const.STORE_AI_INFO,
        aiy : year,
        aim : month,
        ainp : loginUser.numberOfAIpostRequests,
        aini : loginUser.numberOfAIimageRequests
    };

    // we don't await here
    invokeService(Const.OCA_SERVICES_URL, data, this);
  }

  assembleLastSubmittedPost(result) {
    let loginUser = this.state.loginUser;
    let imagePreviewUrl = this.imagePreviewUrl; 
    let videoPreviewUrl = this.videoPreviewUrl;
    let lastSubmittedPost = {
      post : this.state.value,
      postId : result.post_id, 
      time : 'Recently posted',
      userName : loginUser.userName,
      userId : loginUser.userId,
      userPicture : loginUser.userPicture,
      commentsCount : 0,
      likesCount : 0,
      sharesCount : 0,
      numberDate : result.post_pd // this will help finding latest posts
    };

    this.imagePreviewUrl = null; 
    this.videoPreviewUrl = null; 
    if (imagePreviewUrl) {
        lastSubmittedPost.postImg = Const.USER_POST_URL + loginUser.userName + '/' + result.post_pd;
        lastSubmittedPost.imgWidth = result.data.image_width;
        lastSubmittedPost.imgHeight = result.data.image_height;
    }
    if (videoPreviewUrl) {
      lastSubmittedPost.postVideo = Const.USER_VIDEO_URL + loginUser.userName + '/' + lastSubmittedPost.numberDate;
      lastSubmittedPost.imgWidth = this.currentVideoFile.width;
      lastSubmittedPost.imgHeight = this.currentVideoFile.height;
    }
    return lastSubmittedPost;
  }

  addToUserPosts(lastSubmittedPost) {
    let loginUser = this.state.parent.state.loginUser;
    
    if (loginUser.hasOwnProperty('userPosts')) {
      loginUser.userPosts.unshift(lastSubmittedPost);
    }
    else {
      loginUser.userPosts = [];
      loginUser.userPosts[0] = lastSubmittedPost;
    }
    loginUser.userNumberOfPosts++;
    loginUser.postAdded = true;
    if (this.state.grandParent.className === LOGIN_USER) {
      // adding post from login user
      this.state.grandParent.setState({ loginUser : loginUser });
    }
    else {
      // adding post from home or anyuser (this code is verified)
      this.state.parent.setState({loginUser : loginUser})
      this.setState({ postAdded : true });
    }
}

  disableModalWindow(disable) { 
    let body;
    let submitButton;
    let textArea;
    let xCloser;
    let img;
    let label;
    let labelAI;
    let threeDots;
    
    if (disable) {
      body = document.getElementById('main');
      submitButton = document.getElementById('dialog-button');
      textArea = document.getElementById('textArea');
      xCloser = document.getElementById('X');
      img = document.getElementById('img');
      label = document.getElementById('label');
      labelAI = document.getElementById('labelAI');
      threeDots = document.getElementById('spinner');
      if (body && textArea && xCloser) {
          submitButton.setAttribute('disabled', 'disabled');
          textArea.style.opacity = Const.REDUCED_OPACITY; 
          xCloser.style.opacity = Const.REDUCED_OPACITY;  
          if (img) {
            img.style.opacity = Const.REDUCED_OPACITY; 
          }
          if (label) {
            label.style.cursor = 'none';  
            label.style.opacity = Const.REDUCED_OPACITY; 
          }
          if (labelAI) {
            labelAI.style.cursor = 'none';  
            labelAI.style.opacity = Const.REDUCED_OPACITY;  
          }
          if (threeDots) {
            threeDots.style.visibility = 'visible';
          }
          this.allowToggle = false;
          this.setState({ imgIckonDisabled : true });
          if (this.ImageUploadder.current) {
            this.ImageUploadder.current.disableElements();
          }
          this.setState({ submitUserPostNotSentYet : false,  borderUser : this.BOLD_BORDER, fontWeightUser : this.LIGHT_FONT,  });
      }
    }
    else {
      threeDots = document.getElementById('spinner');
      threeDots.style.visibility = 'hidden';
      this.allowToggle = true;
      this.setState({ imgIckonDisabled : false, submitUserPostNotSentYet : true });
    }
  }

  setStateAttributes() {
  }

  getPostContent() {
    return "";
  }

  getIckon() {
    return ( <div></div> )
  }

  getButton() {
    return ( <div></div> )
  }

  getHeader() {
    return "";
  }

  getImageLoader() {
    let activeButton = this.state.activeButton; 
    let styleObj = {};

    if (activeButton ==='button1') {
      styleObj = {display : 'none'}
    }
    return ( 
      <Row className="row-margin-0" style = { styleObj }>                                
        <ImageUpload that = { this } ref={ this.ImageUploadder } />
      </Row> 
    ) 
  }

  getUploadImageIckon() {
  }

  getEmoji() {

  }

  getToAIIckon() {
  }

  // Emoji

  toggleEmojiEmojiPicker = () => {
  };

  handleEmojiClick() {
  }
  
  handleEmojiCloseClick() {
	}

  // AI concierge

  getNumberOfAIRequestsLeftDesktop() {
  }

  getProfileInfo() {
  }

  getProfileInfoDiv(disabledAI) {
    return (
      <div>
        <div className="profile-info">																		
          <div className="user-response">
            <Row>
              { this.getRadioButtons(disabledAI) }
            </Row>																		
          </div>
        </div>
      </div>
    )
  }

  getRadioButtons() {
  }

  getRadioButtonsColumn(disabledAI) {
    let reducedOpacityClass = disabledAI ? 'radioDisable': '';

    return (
        <div id = "radioButtons" className={`radio-container-parent ${reducedOpacityClass}`} >     
          <Row> 

            { this.getRadioButtonColumn('Edit Post', this.AI_POST_EDIT, Edit , 'color1', disabledAI) }

            { this.getRadioButtonColumn('Create Image', this.AI_IMAGE_CREATE, Kotik , 'color2', disabledAI) }

            { this.getRadioButtonColumn('Illustrate Post', this.AI_POST_ILLUSTRATE, Morning, 'color3', disabledAI) }

            { this.getRadioButtonColumn('Video Illustrate Post', this.AI_POST_VIDEO_ILLUSTRATE, Video, 'color4', disabledAI) }

          </Row>
        </div>
    );
  }

  getRadioButtonColumn(optionName, optionInd, img , colorClass, disabledAI) {
    let radioHover = disabledAI ? 'pointer-noview' : 'radio-hover'; 

    if (!disabledAI && (optionInd === this.state.AIrequestOption)) {
      radioHover = 'radio-click';
    }
    return (
        <Col lg={3} md={3} sm={6} xs={6} onClick={ () => this.setAIrequestOptionOnClick(optionInd, disabledAI) }>
          <div className={`form-control-radio ${ colorClass } ${ radioHover }`}>
            <Row className="pl-0 pr-0" >
              <Col lg={12} md={12} sm={0} xs={0} className="pl-0 pr-0">
                <img className="image radioImage" alt="postImage" src= { img }></img>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12} className="fixedHeight pl-0 pr-0">
                  <p>
                    { optionName }
                  </p>
              </Col>
            </Row>
          </div>
        </Col>
    );
  }

  getAIinput() {
  }

  getAIarrowButton() {
  }

  getNumberOfAIRequestsLeftPhone() {
  }

  removeVirtualKeyBoard() {
  }

  noVirtualKeybardAfterDalleImage() {
  }

  getPostReturnArrow() {
  }

  observeOrientationChange() {
  }

  async handleSubmitToAI() {
    this.removeVirtualKeyBoard();
    if ((this.state.AIrequestOption === this.AI_IMAGE_CREATE) || (this.state.AIrequestOption === this.AI_POST_ILLUSTRATE)) {
      await this.daleeRequest();
    }
    else if (this.state.AIrequestOption === this.AI_POST_VIDEO_ILLUSTRATE) {
      await this.videoRequest();
    }
    else {
      await this.chatGptRequest();
    }
  }

  async chatGptRequest() {
    let messageType = this.state.AIrequestType;
    let message = document.getElementById('aiInput').value;
    let data = { type : messageType, message : message};
    let response; 
    let responseFromAI;

    this.disableAIinput(true, true);
    response = await invokeServer(this.localServeUrlAI, data, this); 
    this.disableAIinput(false, true);
    if (this.state.serviceStatusCode === Const.OK) {
      if (response.result) {
        responseFromAI = response.result.generatedContent;
        if (responseFromAI) {
          this.higlightSelectedElement('button2');
          changeHandlerNoEvent(responseFromAI, this);
          this.storeAIinfoInDB();
        }
      }
    } 
  }

  async daleeRequest() {
    let message = document.getElementById('aiInput').value;
    let data = { message : message };
    let response; 
    let daleeImageFile;
    
    this.disableAIinput(true, true);
    response = await invokeServer(this.state.serverUrl, data, this); 
    this.disableAIinput(false, true);
    if (this.state.serviceStatusCode === Const.OK) {
      if (response.result) {
        this.getImageUploadderReadyForDaleeImageOrVideo();
        daleeImageFile = this.createDaleeImageFileForm64BasedByreStream(response.result.img);
        this.setState({ autoFocus : false });
        this.noVirtualKeybardAfterDalleImage();
        getImageParams(daleeImageFile, this.ImageUploadder.current, true);
        this.higlightSelectedElement('button2');
        this.setState({ activeButton : 'button2' });
        this.serviceSelectorForAIimage();
      }
    }
  }

  async videoRequest() {
    let message = document.getElementById('aiInput').value;
    let data = { message : message };
    let response; 
    let videoFile;
    
    this.disableAIinput(true, true);
    response = await invokeServer(this.state.serverUrl, data, this); 
    this.disableAIinput(false, true);
    if (this.state.serviceStatusCode === Const.OK) {
      if (response.result) {
        this.getImageUploadderReadyForDaleeImageOrVideo();
        videoFile = this.createNvidiaVideoFileFromMP4BasedByteStream(response.result.video);
        this.setState({ autoFocus : false });
        this.noVirtualKeybardAfterDalleImage();
        processVideo(videoFile, this.ImageUploadder.current);
        this.higlightSelectedElement('button2');
        this.setState({ activeButton : 'button2' });
        this.serviceSelectorForAIimage();
      }
    }
  }

  getImageUploadderReadyForDaleeImageOrVideo() {
    this.ImageUploadder.current.setState({ imagePreviewUrl : '', videoPreviewUrl : '' }); // remove previous image / video
    this.ImageUploadder.current.parent = this; // repeat main branch processing
  }

  createDaleeImageFileForm64BasedByreStream(imageStream) {
    let buff = Buffer.from(imageStream, 'base64');
    let imageFile = new File([buff], 'dalleImage.PNG', {type: 'image/png'});

    return imageFile;
  }

  createNvidiaVideoFileFromMP4BasedByteStream(videoStream) {
       // Decode the Base64 string
      let binaryString = this.safeAtob(videoStream);
      let len = binaryString.length;
      let bytes = new Uint8Array(len);
      let blob;
      let file;
    
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      // Create a Blob from the Uint8Array
      blob = new Blob([bytes], { type: 'video/mp4' });
      // Create a File object from the Blob
      file = new File([blob], 'myVideo.mp4', { type: 'video/mp4' });
      return file;
  }

  safeAtob(str) {
    try {
      return atob(str);
    } catch (e) {
      return str; // If decoding fails, return the original string
    }
  }

  disableAIinput(disable, changeState) {
    let inputToAI = document.getElementById('aiInput');
    let radioButtons = document.getElementById('radioButtons');
    let threeDots = document.getElementById('spinner');
    let remainingAIrequests = document.getElementById('remainingAIrequests');

    if (disable) {
      if (radioButtons) {
        radioButtons.style.opacity = Const.REDUCED_OPACITY;
      }
      if (threeDots) {
        threeDots.style.visibility = 'visible';
      }
      inputToAI.style.opacity =  Const.REDUCED_OPACITY;
      if (remainingAIrequests) {
        remainingAIrequests.style.opacity = Const.REDUCED_OPACITY;
      }
      if (changeState) {
        this.setState({ submitToAINotSentYet : false,  borderAI : this.BOLD_BORDER, 
          fontWeightAI : this.LIGHT_FONT, disabledAI : true });
      }
    }
    else {
      radioButtons.style.opacity = Const.FULL_OPACITY;
      threeDots.style.visibility = 'hidden';
      inputToAI.style.opacity =  Const.FULL_OPACITY;
      remainingAIrequests.style.opacity = Const.FULL_OPACITY;
      this.setState({ submitToAINotSentYet : true, disabledAI : false });
    }
  }

  getNumberAIRequestsLeft(AIrequestOption) {
    let loginUser = this.state.grandParent.state.loginUser;
    let numberOfAIRequestsLeft;

    if ((AIrequestOption === this.AI_IMAGE_CREATE) || (AIrequestOption === this.AI_POST_ILLUSTRATE) || (AIrequestOption === this.AI_POST_VIDEO_ILLUSTRATE)) {
      numberOfAIRequestsLeft = loginUser.numberOfAIimageRequests;
    }
    else {
      numberOfAIRequestsLeft =  loginUser.numberOfAIpostRequests;
    }
    return numberOfAIRequestsLeft;
  }

  setAIrequestOptionOnClick(AIrequestOption, disabledAI) {
    let numberOfAIRequestsLeft;

    if (!disabledAI) {
      numberOfAIRequestsLeft = this.getNumberAIRequestsLeft(AIrequestOption);
      if (AIrequestOption === this.AI_POST_EDIT) {
        this.setState( { AIrequestOption : AIrequestOption, 
                         AIrequestType : this.AI_POST_EDIT_TYPE,
                         AIrequestPlaceHolder : this.AI_POST_EDIT_PLACE_HOLDER,
                         messageForAI : this.state.editPostMessageForAI,
                         numberOfAIRequestsLeft : numberOfAIRequestsLeft,
                         remainingRequestText : this.REMAINING_AI_POSTS
                       } );
      }
      else if (AIrequestOption === this.AI_POST_CREATE) {
        this.setState( { AIrequestOption : AIrequestOption, 
                         AIrequestType : this.AI_POST_CREATE_TYPE,
                         AIrequestPlaceHolder : this.AI_POST_CREATE_PLACE_HOLDER,
                         messageForAI : this.state.createPostMessageForAI,
                         numberOfAIRequestsLeft : numberOfAIRequestsLeft,
                         remainingRequestText : this.REMAINING_AI_POSTS  
                       } );
      }
      else if (AIrequestOption === this.AI_POST_ILLUSTRATE) {
        this.setState( { AIrequestOption : AIrequestOption, 
                         AIrequestType : this.AI_POST_ILLUSTRATE_TYPE,
                         AIrequestPlaceHolder : this.AI_POST_ILLUSTRATE_PLACE_HOLDER,
                         messageForAI : this.state.createPostMessageForAI,
                         numberOfAIRequestsLeft : numberOfAIRequestsLeft,
                         remainingRequestText : this.REMAINING_AI_IMAGES,
                         serverUrl :  this.localServerUrlIllustrate  
                       } );
      }
      else if (AIrequestOption === this.AI_POST_VIDEO_ILLUSTRATE) {
        this.setState( { AIrequestOption : AIrequestOption, 
                         AIrequestType : this.AI_POST_VIDEO_ILLUSTRATE_TYPE,
                         AIrequestPlaceHolder : this.AI_POST_VIDEO_ILLUSTRATE_PLACE_HOLDER,
                         messageForAI : this.state.createPostMessageForAI,
                         numberOfAIRequestsLeft : numberOfAIRequestsLeft,
                         remainingRequestText : this.REMAINING_AI_IMAGES,
                         serverUrl : this.localServerVideoUrlIllustrate  
                       } );
      }
      else {
        this.setState( { AIrequestOption : AIrequestOption, 
                         AIrequestPrefix : this.AI_IMAGE_CREATE_PREFIX,
                         AIrequestPlaceHolder : this.AI_IMAGE_PLACE_HOLDER,
                         messageForAI : this.state.createImageMessageForAI,
                         numberOfAIRequestsLeft : numberOfAIRequestsLeft,
                         remainingRequestText : this.REMAINING_AI_IMAGES,
                         serverUrl : this.localServeUrlDalee    
                       } );
      }
    }
  }

  async setAIoptionValue(message) {
    let AIrequestOption = this.state.AIrequestOption;

    if (AIrequestOption === this.AI_POST_EDIT) {
      this.setState( {  editPostMessageForAI : message } );
    }
    else if (AIrequestOption === this.AI_POST_CREATE) {
      this.setState( { createPostMessageForAI : message } );
    }
    else {
      this.setState( { createImageMessageForAI : message  } );
    }
  }

  async handleAIinputChange(event) {
    let message;

    event.preventDefault();
    message = document.getElementById('aiInput').value;
    this.setState( { messageForAI : message } );
    this.setAIoptionValue(message);
  }

  aiPostLimitCondition(loginUser) {
    let aiPostLimitCond = (loginUser.numberOfAIpostRequests > 0);

    return aiPostLimitCond;
  }

  aiImageLimitCondition(loginUser) {
    let aiImageLimitCond = (loginUser.numberOfAIimageRequests > 0) || loginUser.hasOwnProperty('marketPlaceCustomerID');

    return aiImageLimitCond;
  }

  serviceSelectorForAIimage() {
    let loginUser = this.state.grandParent.state.loginUser;

    if (loginUser.numberOfAIimageRequests > 0) {
      this.storeAIinfoInDB();
    }
    else if ((loginUser.numberOfAIimageRequests === 0) && loginUser.hasOwnProperty('marketPlaceCustomerID')) {
      this.chargeMarketPlaceCustomer(loginUser);
    }
  }

  chargeMarketPlaceCustomer(loginUser) {
      let data = {
        "mode" : Const.OCA_CHARGE_MARKET_PLACE_CUSTOMER,
        "customer_id": loginUser.marketPlaceCustomerID,
      };
      // we don't await here
      invokeService(Const.OCA_SERVICES_URL, data, this);
  }

  adjustMarketPlaceCustomerText(numberOfAIRequestsLeft, remainingRequestText) {
    let loginUser = this.state.grandParent.state.loginUser;
		let AIrequestOption = this.state.AIrequestOption;
		let adjustedRemainingRequestText;
    let marketPlaceCustomerAddition = ' free ';

		if (((AIrequestOption === this.AI_IMAGE_CREATE) || (AIrequestOption === this.AI_POST_ILLUSTRATE)) && 
		      (numberOfAIRequestsLeft === 0) && 
            loginUser.hasOwnProperty('marketPlaceCustomerID')) {
			adjustedRemainingRequestText = marketPlaceCustomerAddition + remainingRequestText;
		}
		else {
			adjustedRemainingRequestText = remainingRequestText;
		}
    return adjustedRemainingRequestText;
  }

  getAiBackGroundImage(activeButton) {
    if (activeButton === 'button1') {
      return (
        <div>
          <div className="background-text">Imagine</div> 
          <div className="background-text-studio">Studio</div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  render() {
      const { loginUser, header, text, charCounter, modal, autoFocus, postAdded, grandParent, borderAI,
              borderUser, fontWeightUser, backgroundImageClass, activeButton,
              messageForAI, serviceErrorLevel, submitToAINotSentYet, submitUserPostNotSentYet, visibilityHidden, 
              numberOfAIRequestsLeft, disabledAI, remainingRequestText, aiInputClassName,
              AIpostUserNameHeader, postUserNameHeader, showEmojiEmojiPicker, showFaces, showHeaderArea
            } = this.state;

      if (serviceErrorLevel !== Const.NO_ERROR_LEVEL) {
        messageModalWindow(this, header, text); // do it and continue rendering : we are in modal window
      }
      setPostTextOverflow(charCounter, this);
      return (
        <> 
          {
            modal &&  
            iOSstatusBarPadding() &&
            <div >
              <MDBModal className="modal-fullscreen"  isOpen={this.state.modal} toggle={this.toggle} autoFocus={false} overflowScroll = {false}>
                <MDBModalBody id="main" className = { backgroundImageClass }> 
                  <div id="aiBody">

                    {  this.getAiBackGroundImage(activeButton) }
                   
                    {
                      (activeButton === 'button1') && showHeaderArea &&
                      <Row className="alignflex2" >
                        <Col lg={2} xs={1} className="p-0">
                          <div className="modal-myheader">
                            <MdIcons.MdClose id="X" className="modal-close" onClick={this.toggle} />
                          </div>
                        </Col>
                      
                        { this.getNumberOfAIRequestsLeftDesktop(numberOfAIRequestsLeft, remainingRequestText) }

                        <Col lg={2} xs={11}></Col>
                      </Row>
                    }

                    {
                      (activeButton === 'button1') && showFaces &&
                      <Row className="choice">
                        <Col lg={6} xs={6} className="line">
                          <div className="switch" style={{float: 'right'}}>
                            {
                              submitUserPostNotSentYet && submitToAINotSentYet
                              ? <button  className={activeButton === 'button1' ? 'active' : ''}> 
                                  <div id="postAI" className="avatar-ai-landscape" style = { borderAI } >
                                    <img  src={ Consierge } style={{width:'100%'}} className="" alt="Avatar" />													
                                  </div>
                                  <div >																
                                    <h6 className="post-username">{ AIpostUserNameHeader }</h6>
                                  </div>
                                </button>
                              : <button onClick={() => {}}  className="pointer-noview">
                                  <div id="postAI" className="avatar-ai-landscape" style = { borderAI } >
                                    <img  src={ Consierge } style={{ width:'100%', opacity : Const.REDUCED_OPACITY }} className="" alt="Avatar" />													
                                  </div>
                                  <div >																
                                    <h6 className="post-username" style={{ opacity : Const.REDUCED_OPACITY }}>{ AIpostUserNameHeader } </h6>
                                  </div>
                                </button>
                            }                          
                          </div>
                        </Col>
                        <Col lg={6} xs={6} className="pr-0">
                          <div className="switch" style={{float: 'left'}}>
                            {
                              submitToAINotSentYet && submitUserPostNotSentYet
                              ?  <button onClick={() => this.handleButtonClick('button2')} className={activeButton === 'button2' ? 'active' : ''}>
                                  <div id="postUser" className={getUserPictureForAIClassName(loginUser)} style = { borderUser }>
                                    <img src={ loginUser.userPicture } className="" alt="Avatar" />													
                                  </div>
                                  <div>																
                                    <h6 className="post-username"  style = { fontWeightUser }>{ postUserNameHeader }</h6>	
                                  </div>
                                </button>
                              :  <button  className="pointer-noview">
                                 <div id="postUser" className={getUserPictureForAIClassName(loginUser)} style = { borderUser }>
                                    <img src={ loginUser.userPicture } className="" alt="Avatar" />													
                                 </div>
                                  <div>																
                                    <h6 className="post-username"  style = { fontWeightUser }>{ postUserNameHeader }</h6>	
                                  </div>
                                </button>
                            }
                          </div>
                        </Col>
                      </Row> 
                    }
                    
                    { 
                     (activeButton === 'button1')  
                       ? 
                        (
                          <form className="mypostform">  
                              <div className="modal-space">
                                <Row>
                                  <div className="ai-concierge">

                                    <div>
                                      { this.getProfileInfo(disabledAI) }
                                    </div>

                                    <div className={ aiInputClassName }>
                                      <Row>                                
                                        <Col lg={11} md={11} xs={12} className="p-0 ai-input-border">
                                            {
                                              this.getAIinput()
                                            }

                                            { 
                                              this.getPostReturnArrow(messageForAI, submitToAINotSentYet) 
                                            }
                                        </Col>
                                            {
                                              this.getAIarrowButton(messageForAI, submitToAINotSentYet)
                                            }
                                      </Row>
                                    </div>
                                  </div>	
                                </Row>														
                              </div>
                          </form>
                        ) 
                      : 
                      (
                        <div className="">
                          <form onSubmit={this.handleSubmit} className="mypostform">  
                            <Row className="alignflex" >
                                <Col md={9} xs={9} className="p-0">
                                  <div className="modal-myheader">
                                    <MdIcons.MdClose id="X" className="modal-close" onClick={this.toggle} />
                                  </div>
                                </Col>
                                <Col md={3} xs={3} className="p-0 right">
                                  {
                                    this.getButton()
                                  }
                                </Col>
                            </Row>

                            <Row className="row-margin-0 post-row"> 
                              <div className="modal-fullscreen-content">
                                <div className="pr-0 createtextarea">
                                  <label>
                                      <textarea  className="x-input"
                                        autoFocus={autoFocus}
                                        maxLength={this.MAX_LENGTH_STRING}
                                        name="createpost" 
                                        autoCorrect='ON'
                                        id="textArea" 
                                        placeholder="Create Post"  
                                        value={ this.state.value } 
                                        onChange={this.handleChange}>
                                      </textarea>
                                  </label>  
                                </div>
                              </div>                            
                            </Row>
                          </form>
                        </div>
                      )
                    }

                    <div className="modal-fullscreen-content">
                        <form id='uploadImgOrVideoId' className="mypostform"> 
                           
                            { (activeButton === 'button2') && !showEmojiEmojiPicker && getBottomBar(charCounter, this) }
                            
                            { 
                              (activeButton === 'button2') && showEmojiEmojiPicker && getEmojiPicker(this)
                            }

                            { /* making Image Loader available for both AI and User */ }
                            { this.getImageLoader() }

                        </form>
                    </div>

                  </div> 

                     { (activeButton === 'button1') && this.getNumberOfAIRequestsLeftPhone(numberOfAIRequestsLeft, remainingRequestText) }
                   
                     { getSpinner(this, visibilityHidden) }
                </MDBModalBody> 
              </MDBModal>
            </div>                 
              
          }

          {
            postAdded && (grandParent.className !== LOGIN_USER) &&
            <Redirect to={{ pathname: "/Profile", params: { loginUser } }} />
          }

          { this.getIckon() }
          
          {
            messageModalWindow(this, header, text)
          }
        </>   
      );
  }
}